/**
ImageUploadModal
Input text field Component with field error message
@functions:ImageUploadModalComponent
*/
// Core react dependencies
import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/** UI components **/
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { Loader } from '../../_components/layout';

// Translation
import {getTranslate, getLanguages, getActiveLanguage } from 'react-localize-redux';

class ImageUploadModalComponent extends Component {
  static propTypes = {
    label: PropTypes.string,
    openModal: PropTypes.bool,
    handleToggleModal: PropTypes.func,
    placeholderText: PropTypes.string
  }

  static defaultProps = {
    openModal: false
  }

  constructor(props) {
      super(props);
      let showUploader = false;
      if(this.props.src && this.props.src !== undefined)
        showUploader = true;
      else
       showUploader = false;
      this.state = {
        file:this.props.src,
        preview: null,
        showUploader:showUploader
      };
  }
  componentDidUpdate(prevProps) {
      const prevSrc = prevProps.src && prevProps.src;
      const curSrc = this.props.src && this.props.src;
      const hasSrcChanged = prevSrc !== curSrc;
      if(hasSrcChanged){
        let showUploader = false;
        if(this.props.src && this.props.src !== undefined)
          showUploader = true;
        else
         showUploader = false
        this.setState({
          file: this.props.src,
          showUploader:showUploader
        });
      }
  }
  /**
  * onClose
  * when image cropper is closed
  */
  onClose = () => {
    this.setState({preview: null})
  }

  /**
  * onCrop
  * when image is cropped
  */
  onCrop = (preview) => {
    this.setState(
      {
        showUploader: false
      }
    );
    this.setState(
      {
        preview: this.refs.cropper.getCroppedCanvas().toDataURL()
      }
    );
  }
  onImageUpload =(event)=>{
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    })
  }

  onClearImage =()=>{
    this.setState({
      file: null,
      preview:null
    })
  }
  onSubmit =()=>{
    this.props.handleSubmit(this.state.preview);
  }
  render() {
  const {
    openModal,
    handleToggleModal,
    placeholderText,
    translate
  } = this.props;
  const {file, showUploader} = this.state;
  return (
    <Modal isOpen={openModal} toggle={handleToggleModal}
           className={'modal-default modal-md'}>
      <ModalHeader toggle={handleToggleModal}>{translate('Upload image')}</ModalHeader>
      <ModalBody>
        {showUploader &&
        <Row>
          <Col sm="12">
            <Loader />
          </Col>
        </Row>
        }
        <Row className={showUploader?'invisible height-0':'visible'}>
          <Col sm="12">
            <div className="image-cropper-container">
              <Cropper
              ref='cropper'
              aspectRatio={2/1}
              responsive={true}
              src={file}
              guides={false}
              style={{height: 200, width: '100%'}}
              minContainerWidth={160}
              minContainerHeight={160}
              ready={this.onReady}
              crop={this.onCrop} />
            </div>

            </Col>
        </Row>
      </ModalBody>
    <ModalFooter>
      <div className="image-upload-input mr-auto">
        <input
          onChange={this.onImageUpload}
          type="file"
          id="fileupload"
          accept={this.mimeTypes}
        />
        <label htmlFor="fileupload"
        color="primary" className="btn btn-outline-primary btn-md">{placeholderText}</label>
      </div>
      <Button
      block={false}
      size='md'
      color="primary"
      onClick={this.onSubmit}
      >
        {translate('Apply')}
      </Button>
      <Button color="secondary" onClick={handleToggleModal}>{translate('Cancel')}</Button>
    </ModalFooter>
  </Modal>
  );
  }
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.localize),
        languages: getLanguages(state.localize),
        activeLanguage: getActiveLanguage(state.localize).code,
    };
}

ImageUploadModalComponent = connect(mapStateToProps)(ImageUploadModalComponent);
export {ImageUploadModalComponent as ImageUploadModal};
