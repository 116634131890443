
const menu = {
  items: [
    {
      namekey: 'Dashboard',
      urlkey: '',
      icon: 'fas fa-home',
      isHomePage: true
    },
    {
      namekey: 'Analytics',
      urlkey: '/analytics',
      icon: 'fas fa-tachometer',
      allowed_roles: ['admin', 'sysadmin', 'business_admin', 'analyst'],
      children: [
        {
          namekey: 'Dashboard',
          urlkey: '/analytics/dashboard',
          icon: 'fas fa-tachometer',
          allowed_roles: ['admin', 'sysadmin', 'business_admin', 'analyst']
        },
        {
          namekey: 'Views',
          urlkey: '/analytics/item_view',
          icon: 'fas fa-eye',
          allowed_roles: ['admin', 'sysadmin', 'business_admin', 'analyst']
        },
        {
          namekey: 'Favorites',
          urlkey: '/analytics/favorites',
          icon: 'fas fa-heart',
          allowed_roles: ['admin', 'sysadmin', 'business_admin', 'analyst']
        },
        {
          namekey: 'Notifications',
          urlkey: '/analytics/notifications',
          icon: 'fas fa-bell',
          allowed_roles: ['admin', 'sysadmin', 'business_admin', 'analyst']
        },
        {
          namekey: 'Users',
          urlkey: '/analytics/users',
          icon: 'fas fa-users',
          allowed_roles: ['admin', 'sysadmin', 'analyst']
        },
        {
          namekey: 'SoMatch',
          urlkey: '/analytics/somatch',
          icon: 'fas fa-user-friends',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'SOMATCH'
        },
        {
          namekey: 'POS',
          urlkey: '/analytics/pos',
          icon: 'fas fa-shopping-cart',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'POS',
          children:[
            {
              namekey: 'POS_ORDERS',
              urlkey: '/analytics/pos/orders',
              icon: 'fas fa-shopping-cart',
              allowed_roles: ['admin', 'sysadmin'],
              module_code: 'POS',
            },
            {
              namekey: 'POS_PRODUCTS_ORDERED',
              urlkey: '/analytics/pos/product_orders',
              icon: 'fas fa-shopping-cart',
              allowed_roles: ['admin', 'sysadmin'],
              module_code: 'POS',
            }
          ]
        },
        {
          namekey: 'Yango',
          urlkey: '/analytics/yango',
          icon: 'fal fa-route',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'YANGO'
        },
        /*
        {
          namekey: 'Chart Builder',
          urlkey: '/analytics/chartbuilder',
          icon: 'fas fa-tachometer',
          allowed_roles:['admin','sysadmin','analyst']
        }*/
      ]
    },
    {
      title: true,
      namekey: 'Content management',
      wrapper: {
        element: '',
        attributes: {}
      },
      class: '',
      allowed_roles: ['admin', 'sysadmin', 'business_admin']
    },
    {
      namekey: 'Media management',
      urlkey: '/data/media',
      icon: 'far fa-file-image',
      allowed_roles: ['admin', 'sysadmin', 'business_admin'],
      children: [
        {
          namekey: 'Galleries',
          urlkey: '/data/media/galleries',
          icon: 'far fa-images',
          allowed_roles: ['admin', 'sysadmin', 'business_admin']
        },
        {
          namekey: 'Media library',
          urlkey: '/data/media/images',
          icon: 'far fa-image',
          allowed_roles: ['admin', 'sysadmin', 'business_admin']
        }
      ]
    },
    {
      namekey: 'Address',
      urlkey: '/data',
      icon: 'far fa-map-pin',
      allowed_roles: ['admin', 'sysadmin'],
      children: [
        {
          namekey: 'Cities',
          urlkey: '/address/cities',
          icon: 'fas fa-list',
          allowed_roles: ['admin', 'sysadmin']
        },
        {
          namekey: 'Districts',
          urlkey: '/address/districts',
          icon: 'fas fa-list',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'DISTRICT'
        }
      ]
    },
    {
      namekey: 'Data management',
      urlkey: '/data',
      icon: 'far fa-database',
      allowed_roles: ['admin', 'sysadmin'],
      children: [
        {
          namekey: 'Section templates',
          urlkey: '/data/section_templates',
          icon: 'fas fa-clone',
          allowed_roles: ['admin', 'sysadmin']
        },
        {
          namekey: 'Attributes',
          urlkey: '/data/attributes',
          icon: 'fas fa-list',
          allowed_roles: ['admin', 'sysadmin']
        },
        {
          namekey: 'Tags',
          urlkey: '/data/tags',
          icon: 'fas fa-tags',
          allowed_roles: ['admin', 'sysadmin']
        }
      ]
    },
    {
      namekey: 'Categories',
      urlkey: '/data/categories',
      icon: 'fas fa-sitemap',
      allowed_roles: ['admin', 'sysadmin']
    },
    {
      namekey: 'Places',
      urlkey: '/data/places',
      icon: 'far fa-map-marker-alt',
      allowed_roles: ['admin', 'sysadmin', 'business_admin'],
      children: [
        {
          namekey: 'Published',
          urlkey: '/data/places/published',
          icon: 'fal fa-eye',
          allowed_roles: ['admin', 'sysadmin', 'business_admin']
        },
        {
          namekey: 'Draft',
          urlkey: '/data/places/draft',
          icon: 'fal fa-eye-slash',
          allowed_roles: ['admin', 'sysadmin', 'business_admin']
        },
        {
          namekey: 'Featured',
          urlkey: '/data/places/featured',
          icon: 'fal fa-stars',
          allowed_roles: ['admin', 'sysadmin']
        },
        {
          namekey: 'Export',
          urlkey: '/data/places/export',
          icon: 'fal fa-file-export',
          allowed_roles: ['admin', 'sysadmin']
        },
        {
          namekey: 'Import',
          urlkey: '/data/places/import',
          icon: 'fal fa-file-import',
          allowed_roles: ['admin', 'sysadmin']
        }
      ]
    },
    {
      namekey: 'Events',
      urlkey: '/data/events',
      icon: 'fal fa-calendar-alt',
      allowed_roles: ['admin', 'sysadmin', 'business_admin'],
      children: [
        {
          namekey: 'Published',
          urlkey: '/data/events/published',
          icon: 'fal fa-eye',
          allowed_roles: ['admin', 'sysadmin', 'business_admin']
        },
        {
          namekey: 'Draft',
          urlkey: '/data/events/draft',
          icon: 'fal fa-eye-slash',
          allowed_roles: ['admin', 'sysadmin', 'business_admin']
        },
        {
          namekey: 'Featured',
          urlkey: '/data/events/featured',
          icon: 'fal fa-stars',
          allowed_roles: ['admin', 'sysadmin']
        }
      ]
    },
    {
      namekey: 'Import',
      urlkey: '/importer/import',
      icon: 'far fa-upload',
      allowed_roles: ['admin', 'sysadmin'],
      children: [
        {
          namekey: 'Media',
          urlkey: '/importer/import/media',
          icon: 'far fa-image',
          allowed_roles: ['admin', 'sysadmin']
        },
        {
          namekey: 'Items',
          urlkey: '/importer/import/items',
          icon: 'far fa-map-marker-alt',
          allowed_roles: ['admin', 'sysadmin']
        },
        {
          namekey: 'Logs',
          urlkey: '/importer/imported_files',
          icon: 'far fa-history',
          allowed_roles: ['admin', 'sysadmin']
        }
      ]
    },
    {
      title: true,
      namekey: 'User accounts',
      wrapper: {
        element: '',
        attributes: {}
      },
      class: '',
      allowed_roles: ['admin', 'sysadmin']
    },
    {
      namekey: 'navigation_users',
      urlkey: '/account/users',
      icon: 'fas fa-users',
      allowed_roles: ['admin', 'sysadmin']
    },
    {
      namekey: 'Roles',
      urlkey: '/account/roles',
      icon: 'fas fa-user-tag',
      allowed_roles: ['admin', 'sysadmin']
    },

    {
      title: true,
      namekey: 'Fanwall',
      wrapper: {
        element: '',
        attributes: {}
      },
      class: '',
      allowed_roles: ['admin', 'sysadmin']
    },
    {
      namekey: 'Post',
      urlkey: '/content/posts',
      icon: 'fas fa-comments',
      allowed_roles: ['admin', 'sysadmin']
    },
    {
      namekey: 'report',
      urlkey: '/content/report',
      icon: 'fas fa-comment-exclamation',
      allowed_roles: ['admin', 'sysadmin']
    },

    {
      title: true,
      namekey: 'Modules',
      wrapper: {
        element: '',
        attributes: {}
      },
      class: '',
      allowed_roles: ['admin', 'sysadmin'],
      module_code: 'PRONOSTICS'
    },
    {
      namekey: 'Pronostic',
      urlkey: '/pronostic',
      icon: 'fas fa-coins',
      allowed_roles: ['admin', 'sysadmin'],
      module_code: 'PRONOSTICS',
      children: [
        {
          namekey: 'Bet types',
          urlkey: '/pronostic/bet_types',
          icon: 'fas fa-shield-check',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'PRONOSTICS'
        },
        {
          namekey: 'Game types',
          urlkey: '/pronostic/gametypes',
          icon: 'fas fa-puzzle-piece',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'PRONOSTICS'
        },
        {
          namekey: 'Participant types',
          urlkey: '/pronostic/participant_types',
          icon: 'fas fa-users-class',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'PRONOSTICS'
        },
        {
          namekey: 'Tournaments',
          urlkey: '/pronostic/tournaments',
          icon: 'fas fa-trophy',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'PRONOSTICS'
        },
        {
          namekey: 'Games',
          urlkey: '/pronostic/games',
          icon: 'fas fa-futbol',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'PRONOSTICS'
        },
        {
          namekey: 'Participants',
          urlkey: '/pronostic/participants',
          icon: 'fas fa-users-crown',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'PRONOSTICS'
        },
        {
          namekey: 'Import matches',
          urlkey: '/pronostic/importer/tournament',
          icon: 'fal fa-file-import',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'PRONOSTICS'
        },
        /*{
          namekey: 'Ranking',
          urlkey: '/pronostic/ranking',
          icon: 'fas fa-sort-amount-up',
          allowed_roles:['admin','sysadmin'],
          module_code:'PRONOSTICS'
        },*/
      ]
    },
    {
      namekey: 'POS',
      urlkey: '/pos',
      icon: 'fas fa-shopping-cart',
      module_code: 'POS',
      children: [
        {
          namekey: 'POS_Categories',
          urlkey: '/pos/categories',
          icon: 'fas fa-sitemap',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'POS'
        },
        {
          namekey: 'POS_Products',
          urlkey: '/pos/products',
          icon: 'fas fa-burger-soda',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'POS'
        },
        {
          namekey: 'POS_Goodies',
          urlkey: '/pos/goodies',
          icon: 'fas fa-gift',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'POS'
        },
        {
          namekey: 'POS_Merchant',
          urlkey: '/pos/merchants',
          icon: 'fas fa-user',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'POS'
        },
        {
          namekey: 'POS_Orders',
          urlkey: '/pos/orders',
          icon: 'fas fa-book',
          allowed_roles: ['admin', 'sysadmin', 'business_admin'],
          module_code: 'POS'
        },
        {
          namekey: 'POS_Payment_Accounts',
          urlkey: '/pos/payment_accounts',
          icon: 'fas fa-sim-card',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'POS'
        },
        {
          namekey: 'POS_Payment_Operators',
          urlkey: '/pos/payment_operators',
          icon: 'fas fa-sim-card',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'POS'
        },
        {
          namekey: 'POS_Payment_Transactions',
          urlkey: '/pos/payment_transactions',
          icon: 'fas fa-money-check-alt',
          allowed_roles: ['admin', 'sysadmin'],
          module_code: 'POS'
        },
      ]
    },
    {
      title: true,
      namekey: 'Support',
      icon: 'fas fa-user-headset',
      wrapper: {
        element: '',
        attributes: {}
      },
      class: '',
      module_code: 'TICKET'
    },
    {
      namekey: 'Tickets',
      urlkey: '/support/tickets',
      icon: 'fas fa-inbox-in',
      module_code: 'TICKET'
    },
    {
      title: true,
      namekey: 'System',
      icon: 'fas fa-rocket',
      wrapper: {
        element: '',
        attributes: {}
      },
      class: ''
    },
    /*{
      namekey: 'Help',
      urlkey: process.env.REACT_APP_WEBSITE_URL+'/assistance',
      external_url:true,
      icon: 'fas fa-question-circle'
    },
    {
      namekey: 'Help',
      urlkey: '/assistance',
      icon: 'fas fa-question-circle'
    },*/
    {
      namekey: 'Client Applications',
      urlkey: '/system/client-apps',
      icon: 'fas fa-rocket',
      allowed_roles: ['admin', 'sysadmin']
    },
    {
      namekey: 'Notifications',
      icon: 'fas fa-bell',
      urlkey: '/system',
      allowed_roles: ['admin', 'sysadmin', 'business_admin'],
      children: [
        /*  {
            namekey: 'Provider Apps',
            urlkey: '/system/notification-providers',
            icon: 'fas fa-screwdriver',
            allowed_roles:['admin','sysadmin']
          },*/
        {
          namekey: 'Devices',
          urlkey: '/system/devices',
          icon: 'fas fa-mobile-alt',
          allowed_roles: ['admin', 'sysadmin']
        },
        {
          namekey: 'Notifications',
          urlkey: '/system/notifications',
          icon: 'fas fa-bell',
          allowed_roles: ['admin', 'sysadmin', 'business_admin']
        }
      ]
    },
    {
      namekey: 'Subscription',
      urlkey: '/subscription/product',
      icon: 'fas fa-rocket',
      allowed_roles: ['admin', 'sysadmin'],
      module_code: 'PAYMENT'
    },
    {
      namekey: 'Configuration',
      urlkey: '/system/app-configurations/',
      icon: 'fas fa-cogs',
      allowed_roles: ['admin', 'sysadmin']
    }
  ]
};
export default menu;
