// Core react libs
import React, { Component, Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

// Translation
import { getTranslate, getLanguages, getActiveLanguage } from 'react-localize-redux';

// UI components
import { Container } from 'reactstrap';

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer
} from '@coreui/react';


import navigation from '../../_nav';
import SideBar from './SideBar';

import {AuthActivateMessage, BreadCrumb, Loader, SettingsSideBar} from '../../_components/layout';
import { translateActions, layoutActions } from '../../_services/actions';
import { ticketActions } from '../../_services/actions/tickets';
import { urlConfig, activatedModules } from '../../_config';
import { setLastUrl } from '../../_helpers';
import { SectionsMobilePreviewAsideBlock } from '../../_pages/Data/Item/Blocks/Section/SectionsMobilePreviewAsideBlock';
import { AdditionalInformationAsideBlock } from '../../_pages/Support/Ticket/EditTicket/Blocks/TicketDetail/AdditionalInformationAsideBlock';


const DefaultHeader = React.lazy(() => import('./DefaultHeader'));
const DefaultFooter = React.lazy(() => import( '../../_components/layout/DefaultFooter'));

class DefaultLayout extends Component {
  loading = () => <div className="animated fadeIn pt-1 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

  constructor(props) {
      super(props);
      //to setup the translation
      this.props.dispatch(
        translateActions.addTranslationForPage(
        this.props.languages,
        'Layout',
        'Navigation'
        )
      );

      this.props.dispatch(
        translateActions.addTranslationForPage(
        this.props.languages,
        'Layout',
        'SettingsBar'
        )
      );

      this.props.dispatch(
        translateActions.addTranslationForPage(
        this.props.languages,
        'General',
        'Labels'
        )
      );

      this.props.dispatch(
        translateActions.addTranslationForPage(
        this.props.languages,
        'Messages',
        'Alert'
        )
      );
      const pages = ['Common','User','Address','Notification','ClientAppField','Category','Item','Media'];
      this.props.dispatch(
        translateActions.addTranslationForValidation(
        this.props.languages,
        'Messages',
        'Validation',
         pages
        )
      );

      this.state = {
        ticket_unseen_count:0,
        loaded: false
      };

      //To init the breadcrumbs
      this.props.dispatch(layoutActions.initBreadcrumb());

      // To show the page loader
      this.props.dispatch(layoutActions.showLoader());

      // To add the section aside bar default classes
      document.body.classList.add('aside-section-menu-fixed');
      document.body.classList.add('aside-section-menu-off-canvas');

      //to get the unseen count for tickets
      if(activatedModules.includes('TICKET'))
        this.getTicketUnseenCount();

  }

  componentWillMount() {
    // To set the last url when container loads
    setLastUrl(this.props.history.location.pathname, this.props);

    // To set the last url when location changes
    this.unlisten = this.props.history.listen((location, action) => {
      setLastUrl(location.pathname, this.props);
    });
  }

  componentDidUpdate(prevProps) {
    const prevLangCode = prevProps.activeLanguage && prevProps.activeLanguage;
    const curLangCode = this.props.activeLanguage && this.props.activeLanguage;
    const hasLanguageChanged = prevLangCode !== curLangCode;
    const loaded = this.state.loaded;
    //to get the unseen count for tickets
    if(prevProps.path !== this.props.path)
      this.getTicketUnseenCount();

    if(hasLanguageChanged || !loaded){
        // To set the active language in the url
        this.setNavItemUrl(navigation.items);
        this.setState({
          loaded: true,
        });
    }
    if(prevProps.show_section_aside_bar !== this.props.show_section_aside_bar){
      // To add the class to show the aside menu
      let body_element = document.body;
      if(this.props.show_section_aside_bar)
       body_element.classList.add('aside-section-menu-lg-show');
      else
       body_element.classList.remove('aside-section-menu-lg-show');
    }
    if(prevProps.show_settings_aside_bar !== this.props.show_settings_aside_bar){
      // To add the class to show the aside menu
      let body_element = document.body;
      if(this.props.show_settings_aside_bar)
       body_element.classList.add('aside-settings-menu-lg-show');
      else
       body_element.classList.remove('aside-settings-menu-lg-show');
    }
  }

  componentWillUnmount() {
      this.unlisten();
  }

  /**
  * To get unseen count from API
  */
  getTicketUnseenCount=()=>{
    ticketActions.getUnseenCount(this.props.dispatch, this.onTicketUnseenCountLoadedSuccess, this.onDataLoadFailure);
  }

  /**
  * When tickets unseencount loaded
  * @param {Object} response
  */
  onTicketUnseenCountLoadedSuccess=(response)=>{
    this.setState({
      ticket_unseen_count:response.data
    },
    ()=>{
      //to set the unseen count in the menu
      this.setNavItemBadges(navigation.items, this.state.ticket_unseen_count);
    });
  }

  /**
  * When HTTP request failed
  * @param {Object} error
  */
  onDataLoadFailure=(response)=>{

  }

  /**
  * To check if access is denied for this user
  * @param {array} allowedRoles
  * @param {Object} user
  */
  isAccessDenied = (allowedRoles, user) => {
    if(this.props.history.location.pathname === `${urlConfig.BASE_URL}/${this.props.activeLanguage}/access_denied`)
     return false;
    if(!user)
     return true;
    if(allowedRoles && allowedRoles.length > 0){
      if(allowedRoles.includes(user.role.slug) && (user.role.backoffice_access === 1) && user.activated ===1)
        return false;
      else
        return true;
    }
    else{
      return false;
    }
  }

  /**
  * To set the Navitem URL with the language code
  * @param {array} navItems
  */
  setNavItemUrl = (navItems) => {
    const { translate, activeLanguage } = this.props;
    navItems.map((item,index)=>{
      if(item.children){
        item.name = translate(item.namekey);
        item.url = item.urlkey;
        this.setNavItemUrl(item.children);

        let accessDenied = false;
        if(item.allowed_roles &&
          !(item.allowed_roles.includes(this.props.user.role.slug))
          )
          accessDenied = true;
        if(item.module_code && !activatedModules.includes(item.module_code))
          accessDenied=true;
        if(!accessDenied){ //show the menu
          item.class="visible";
        }
        else{ // Hide the menu
          item.class="d-none";
        }

      }
      else{
        let accessDenied = false;
        if(item.allowed_roles &&
          !(item.allowed_roles.includes(this.props.user.role.slug))
          )
          accessDenied = true;

        if(item.module_code && !activatedModules.includes(item.module_code))
          accessDenied=true;

        if(!accessDenied){ //show the menu
          item.class="visible";
          item.name = translate(item.namekey);
          if(item.external_url)
          item.url = item.urlkey;
          else{
          if(item.urlkey)
            item.url = `${urlConfig.BASE_URL}/${activeLanguage}${item.urlkey}`;
          else if(item.urlkey === '')
            item.url = `${urlConfig.BASE_URL}/${activeLanguage}`;
          }
        }
        else{ // Hide the menu
          item.class="d-none";
        }
      }
      return true;
    });
  }

  /**
  * To set the badges for the notification or unseen count
  * @param {array} navItems
  */
  setNavItemBadges = (navItems, ticket_unseen_count) => {
    navItems.map((item,index)=>{
        if(item.urlkey === '/support/tickets' && ticket_unseen_count.amount > 0){
          item.badge = {
            variant: 'info',
            text: ticket_unseen_count.amount
          }
        }
      return true;
    });
  }

  render() {
    const { translate, page_loader, activeLanguage, user, breadcrumbs, allowed_roles, component: Component, ...rest } = this.props;

    return (
      <Route {...rest} render={props => (
        !this.isAccessDenied(allowed_roles, user)
        ?
        <div className={`app ${this.props.show_section_aside_bar?'aside-section-menu-show':''}`}>
          <AuthActivateMessage {...props} />
          <AppHeader className="border-bottom-dotted" fixed>
            <Suspense fallback={this.loading()}>
              <DefaultHeader {...props}/>
            </Suspense>
          </AppHeader>
          <div className="app-body">
            <AppSidebar fixed display="lg">
              <AppSidebarHeader />
              <AppSidebarForm />
              <SideBar navConfig={navigation} {...props} activelanguage={this.props.activeLanguage} />
              <AppSidebarFooter />
              <AppSidebarMinimizer />
            </AppSidebar>
            <main className="main">
              {
                breadcrumbs.length > 0 && <BreadCrumb appRoutes={breadcrumbs}/>
              }
              <Container fluid className={(!page_loader ? 'd-block' : 'd-none')}>
                <div id="my-wrapper">
                  <Suspense fallback={this.loading()}>
                  <Component {...props} />
                  </Suspense>
                </div>
              </Container>
              <div className={"loader-container "+(page_loader ? 'd-flex ' : 'd-none ')+(breadcrumbs.length > 0 ?'with-breadcrumb ':'without-breadcrumb ')}>
                <Loader />
              </div>
            </main>
            <AppAside fixed hidden>
            </AppAside>
            <SectionsMobilePreviewAsideBlock  />
            <AdditionalInformationAsideBlock  />
            <SettingsSideBar  />
          </div>
          <AppFooter>
            <Suspense fallback={this.loading()}>
              <DefaultFooter />
            </Suspense>
          </AppFooter>
        </div>
        : <Redirect to={{ pathname: `${urlConfig.BASE_URL}/${activeLanguage}/access_denied`, state: { from: props.location } }} />

      )} />

    );
  }
}

const composeRouter = compose(
  withRouter
)
const mapStateToProps = state => (
  {
    activeLanguage: getActiveLanguage(state.localize).code,
    translate: getTranslate(state.localize),
    languages: getLanguages(state.localize),
    user: state.authentication.user,
    breadcrumbs:state.layout.breadcrumbs,
    page_loader:state.layout.page_loader,
    show_section_aside_bar:state.layout.show_section_aside_bar,
    show_settings_aside_bar:state.layout.show_settings_aside_bar
  }
);
DefaultLayout = composeRouter(DefaultLayout)
DefaultLayout = connect(mapStateToProps)(DefaultLayout);
export default DefaultLayout;
