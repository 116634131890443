/**
authentication
The reducer function to define state for authentication requests
*/

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user, token_expires_in:null, refresing_token:false , should_update_password:false} : {loggedIn:false, loggingIn:false, user:null, error:null};
export function authentication(state = initialState, action) {
  switch (action.type) {
    case 'AUTH_AUTHENTICATION_INIT':
      return {loggedIn:false, loggingIn:false, user:null, error:null}
    case 'AUTH_LOGIN_START':
      return {loggedIn:false, loggingIn:true, user:null, error:null}
    case 'AUTH_LOGIN_SUCCESS':
      return {
        loggedIn: true,
        loggingIn:false,
        user: action.payload,
        token_expires_in:true,
        error:null
      };
    case "AUTH_LOGIN_FAILURE":
      return {loggingIn: false,loggedIn: false, user:null, error:action.payload.json?action.payload.json:action.payload};
    case "AUTH_LOGOUT":
      return {loggedIn:false, loggingIn:false, user:null, error:null};
    case "AUTH_ACTIVATED":
      return Object.assign({}, state.user, {
          activated: 1
        });
    case "AUTH_TOKEN_REFRESH_REQUEST":
      return {
        loggedIn: true,
        loggingIn:false,
        user: state.user,
        token_expires_in:true,
        error:null,
        refresing_token:true
      };
    case "AUTH_TOKEN_REFRESH_SUCCESS":
      return {
        loggedIn: true,
        loggingIn:false,
        user: state.user,
        token_expires_in:true,
        error:null,
        refresing_token:false
      };
    case 'AUTH_USER_UPDATED':
    /*  state.user = action.payload;
      return state;*/
      return {
        loggedIn: true,
        loggingIn:false,
        user: action.payload,
        token_expires_in:true,
        error:null,
        refresing_token:false
      };

    case 'AUTH_SHOULD_UPDATE_PWD':
    return Object.assign({}, state.user, {
        should_update_password: true
      });
    case 'AUTH_PWD_UPDATED':
    return Object.assign({}, state.user, {
        should_update_password: false
      });
    default:
      return state;
  }
}
