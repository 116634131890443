/**
Redirection: to define the configuration for handling redirection
*/

export const redirectionConfig = {
    SKIP_URLS_TO_REDIRECT_AFTERLOGIN:[
    '/account/login',
    '/account/forgot-password',
    '/account/reset-password',
    '/account/activate',
    '/access_denied',
    '/account/change-password'
    ],
    URLS_TO_VISIT_WITHOUT_LOGIN:[
    'account/forgot-password',
    'account/reset-password',
    'account/activate',
    'account/login',
    'account/change-password'
    ],
};
