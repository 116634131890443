import { getActiveLanguage } from 'react-localize-redux';

import HttpHeader from './headers';
import HttpResponseError from './response-error';
import { sessionActions, authActions } from '../actions';
import { store } from '../../_helpers';
import { urlConfig } from '../../_config';

export default class HttpRequest {
	constructor() {
		this.headers = new HttpHeader();
	}

	__options(method, body = undefined) {
		this.headers.delete('content-type');
		// to set the currect locale
		const state = store.getState();
		if(getActiveLanguage(state.localize)){
			const locale = getActiveLanguage(state.localize).code;
			this.headers.language = locale;
		}
		// to set the token in the Authorization header
		this.headers.authorization = `Bearer ${localStorage.getItem('access_token')}`;

		// to handle the multipart form submission to upload images
		if(method === "POST_FORM")
			method = "POST"; //contentType should not set for multipart form
		else
		  this.headers.contentType = 'application/json';

		const options = {
			method,
			headers: this.headers.toDictionary()
		};

		if (body !== undefined) {
			if (this.headers.contentType === 'application/json') {
				options.body = JSON.stringify(body);
			}
			else {
				options.body = body;
			}
		}
		return options;
	}

	get authorization() { return this.headers.authorization; }
	set authorization(value) { this.headers.authorization = value; }

	__request(method, url, body = undefined) {
		const state = store.getState();
		if(state.authentication.user !== null
			&& state.authentication.token_expires_in === null){ //Need to refresh token
			if(!state.authentication.refresing_token)	{
				return this.refreshToken(method, url, body);
			}
		}
		else{ // Make the API call
			return this.makeApiRequest(method, url, body)
		}
	}

	refreshToken=(method, url, body)=>{
		store.dispatch(this.tokenRefreshRequest())
		let refresh_token = localStorage.getItem('refresh_token');
		let bodydata={refresh_token};
		let promise = fetch(urlConfig.API_URL+'oauth/token/refresh', this.__options('POST', bodydata))
			.then((response) => {
				if (!response.ok) {
						return response
						.json()
						.then((errorJSON) => {
							store.dispatch(authActions.logout());
						});
				}
				else {
						return response
						.json()
						.then((response) => {
							let data = response;
			        // to set the localStorage
			        localStorage.setItem('access_token', data.data.access_token);
			        localStorage.setItem('refresh_token', data.data.refresh_token);
			        localStorage.setItem('expires_in', data.data.expires_in);
							store.dispatch(sessionActions.setSession(data.data));
							return this.makeApiRequest(method, url, body);
						});

				}
			})
			.catch((response)=>{
				store.dispatch(authActions.logout());
			});
		return promise;
	}

	tokenRefreshRequest =()=> {
	   return { type: 'AUTH_TOKEN_REFRESH_REQUEST' }
	}

	makeApiRequest = (method, url, body = undefined)=>{
		return fetch(url, this.__options(method, body))
			.then((response) => {
				if (!response.ok) {
					return response
						.json()
						.then((errorJSON) => {
							throw new HttpResponseError(response, errorJSON);
						});
				}
				return response;
			});
	}
	getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) =>{
			/*if(key === 'medias'){
				object[key].map( (media, media_index) =>{
						formData.append(key + '[' + media_index + ']', media.url, media['filename']);
					return true;
				});
			}
			else */
			if(key === 'medias' || key === 'file' || key === 'icon_file'|| key === 'cover_image_file'){
				formData.append(key, object[key], object['filename']);
			}
			else
				formData.append(key, object[key]);
		} );
    return formData;
	}

	get(url) {
		return this.__request('GET', url).then(data => data.json());
	}
	post(url, body = undefined) {
		return this.__request('POST', url, body).then(data =>
			data.json()
			.then((response) => {
				return response;
			})
			.catch((response)=>{
				return {
					'type':"success",
					'message':"",
					'status':"204"
				};
			})
		);
	}
	patch(url, body = undefined) {
		return this.__request('PATCH', url, body).then(data => data.json());
	}
	put(url, body = undefined) {
		return this.__request('PUT', url, body).then(data => data.json());
	}
	delete(url) {
		return this.__request('DELETE', url).then(data =>
			data.json()
			.then((response) => {
				return response;
			})
			.catch((response)=>{
				return {
					'type':"success",
					'message':"",
					'status':"204"
				};
			})
		);
	}
	postFormData(url, body = undefined) {
		return this.__request('POST_FORM', url, this.getFormData(body)).then(data => data.json());
	}
}
